import React from 'react';

const Privacy = () => {
    return ( <div class="WordSection1">

    <p class="MsoNormal"><b><span >Privacy Policy</span></b></p>
    
    <p class="MsoNormal"><span >Last updated April 5, 2025</span></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    <p class="MsoNormal"><span >Palace LLC (“we,” “us,” or “our”) operates the Palace
    mobile application (the “App”) and the </span><a href="https://thisispalace.com" >https://thisispalace.com</a>&nbsp;<span >
    website (the “Site”) (collectively, the “Services”). We are committed to
    protecting the privacy of our users. This Privacy Policy explains how we
    collect, use, store, and share your information when you use our Services.</span></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    <p class="MsoNormal"><b><span >1. Scope of This Policy</span></b></p>
    
    <p class="MsoNormal"><span >This Privacy Policy applies to all visitors, users, and
    others who access or use our Services. By using our Services, you agree to the
    collection and use of information in accordance with this Privacy Policy.</span></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    <p class="MsoNormal"><b><span >2. Information We Collect</span></b></p>
    
    <p class="MsoNormal"><b><span >2.1. Account Creation Information</span></b></p>
    
    <p class="MsoNormal"><span >When you create an account on the App or Site, we collect:</span></p>
    
    <p class="MsoListParagraphCxSpFirst"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Email
    Address</span></p>
    
    <p class="MsoListParagraphCxSpMiddle"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Name</span></p>
    
    <p class="MsoListParagraphCxSpMiddle"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Birthday
    (to verify age eligibility)</span></p>
    
    <p class="MsoListParagraphCxSpLast"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Password
    (encrypted)</span></p>
    
    <p class="MsoNormal"><b><span >2.2. User-Generated Content (UGC)</span></b></p>
    
    <p class="MsoNormal"><span >We allow users to submit content related to bars and
    nightlife, including:</span></p>
    
    <p class="MsoListParagraphCxSpFirst"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Bars
    you save (collections)</span></p>
    
    <p class="MsoListParagraphCxSpMiddle"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Reviews
    posted about bars</span></p>
    
    <p class="MsoListParagraphCxSpMiddle"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Age
    group votes</span></p>
    
    <p class="MsoListParagraphCxSpMiddle"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Bar
    star ratings</span></p>
    
    <p class="MsoListParagraphCxSpMiddle"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Images
    you upload</span></p>
    
    <p class="MsoListParagraphCxSpMiddle"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >“Suggest
    an Edit” submissions</span></p>
    
    <p class="MsoListParagraphCxSpLast"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Contact
    us form submissions</span></p>
    
    <p class="MsoNormal"><span >All user-generated content is associated with your account
    identity.</span></p>
    
    <p class="MsoNormal"><b><span >2.3. Device Information</span></b></p>
    
    <p class="MsoNormal"><span >We collect device identifiers (e.g., Device ID) to help us
    detect and prevent malicious or excessive voting practices.</span></p>
    
    <p class="MsoNormal"><b><span >2.4. Location Data</span></b></p>
    
    <p class="MsoNormal"><span >If you opt in to location services, we collect precise location
    data from your mobile device. This is used to:</span></p>
    
    <p class="MsoListParagraphCxSpFirst"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Show
    nearby bars</span></p>
    
    <p class="MsoListParagraphCxSpLast"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Derive
    insights on bar popularity</span></p>
    
    <p class="MsoNormal"><b><span >2.5. Advertising Data</span></b></p>
    
    <p class="MsoNormal"><span >We collect usage data such as the number of times
    advertisements are viewed. This data is not tied to your personal identity and
    is used for analytics and ad performance metrics.</span></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    <p class="MsoNormal"><b><span >3. How We Use Your Information</span></b></p>
    
    <p class="MsoNormal"><b><span >1. Account Management:</span></b></p>
    
    <p class="MsoListParagraphCxSpFirst"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >To
    create and maintain your user account.</span></p>
    
    <p class="MsoListParagraphCxSpMiddle"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >To
    verify that you meet the age requirement (18+)</span></p>
    
    <p class="MsoListParagraphCxSpLast"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >To
    send you promotional information via email, which you can opt out of by
    clicking the “unsubscribe” link in any promotional email.</span></p>
    
    <p class="MsoNormal"><b><span >2. User-Generated Content Display &amp; App
    Functionality:</span></b></p>
    
    <p class="MsoListParagraphCxSpFirst"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >To
    display your submissions (comments, votes, bar ratings) to other users.</span></p>
    
    <p class="MsoListParagraphCxSpMiddle"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >To
    keep bar information up-to-date based on user feedback.</span></p>
    
    <p class="MsoListParagraphCxSpLast"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Basic
    app functionality purposes.</span></p>
    
    <p class="MsoNormal"><b><span >3. Device ID &amp; Security:</span></b></p>
    
    <p class="MsoListParagraph"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >To
    monitor and prevent abuse or malicious voting.</span></p>
    
    <p class="MsoNormal"><b><span >4. Location Data:</span></b></p>
    
    <p class="MsoListParagraphCxSpFirst"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >To
    provide location-based services (e.g., showing bars in your vicinity).</span></p>
    
    <p class="MsoListParagraphCxSpLast"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >To
    generate insights about bar popularity without linking this data to your
    personal identity.</span></p>
    
    <p class="MsoNormal"><b><span >5. Advertising Analytics:</span></b></p>
    
    <p class="MsoListParagraph"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >To
    measure ad performance and how often ads are viewed by users, without linking
    this to your personal information.</span></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    <p class="MsoNormal"><b><span >4. Data Sharing and Disclosure</span></b></p>
    
    <p class="MsoNormal"><span >To send you promotional information via email, which you
    can opt out of by clicking the “unsubscribe” link in any promotional email.</span></p>
    
    <p class="MsoNormal"><b><span >1. Business Transfers:</span></b></p>
    
    <p class="MsoNormal"><span >If we are involved in a merger, acquisition, or asset sale,
    your information may be transferred. We will provide notice before your
    personal information is transferred and becomes subject to a different Privacy
    Policy.</span></p>
    
    <p class="MsoNormal"><b><span >2. Legal Compliance and Safety:</span></b></p>
    
    <p class="MsoNormal"><span >We may disclose information if required to do so by law or
    in response to valid requests by public authorities. We may also disclose
    information in good faith to protect our rights, user safety, or investigate
    fraud.</span></p>
    
    <p class="MsoNormal"><b><span >3. Service Providers (If any):</span></b></p>
    
    <p class="MsoNormal"><span >If we engage service providers (e.g., hosting, analytics)
    that process data on our behalf, they will only have access to the data
    necessary to perform tasks on our behalf and are obligated not to disclose or
    use it for other purposes.</span></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    <p class="MsoNormal"><b><span >5. Your Rights and Choices</span></b></p>
    
    <p class="MsoNormal"><b><span >1. Access and Update:</span></b></p>
    
    <p class="MsoNormal"><span >You can review, edit, or delete some of your personal
    information directly within the App. Certain account details (like your name
    and birthday) can be updated in your account settings.</span></p>
    
    <p class="MsoNormal"><b><span >&nbsp;2. Deletion:</span></b></p>
    
    <p class="MsoNormal"><span >Users may delete or request the deletion of their content
    or account. We may retain copies of some data for business or legal compliance
    purposes, even after you delete it, as permitted or required by law.</span></p>
    
    <p class="MsoNormal"><b><span >3. Email Communications:</span></b></p>
    
    <p class="MsoNormal"><span >You can opt out of promotional emails at any time by
    clicking the “unsubscribe” link in the email.</span></p>
    
    <p class="MsoNormal"><b><span >4. Location Services:</span></b></p>
    
    <p class="MsoNormal"><span >You can revoke location access at any time by adjusting
    your device’s settings. Once revoked, we will no longer collect precise
    location data.</span></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    <p class="MsoNormal"><b><span >6. Data Retention</span></b></p>
    
    <p class="MsoNormal"><span >We retain your personal data for as long as necessary to:</span></p>
    
    <p class="MsoListParagraphCxSpFirst"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Provide
    and improve our services</span></p>
    
    <p class="MsoListParagraphCxSpMiddle"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Comply
    with legal obligations</span></p>
    
    <p class="MsoListParagraphCxSpMiddle"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Enforce
    our agreements and policies</span></p>
    
    <p class="MsoListParagraphCxSpLast"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Resolve
    disputes</span></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    <p class="MsoNormal"><b><span >7. Security Measures</span></b></p>
    
    <p class="MsoNormal"><span >We take the security of your data seriously and implement
    technical and organizational measures, including:</span></p>
    
    <p class="MsoListParagraphCxSpFirst"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >SSL
    for data transfers</span></p>
    
    <p class="MsoListParagraphCxSpLast"><span >-<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span >Secure
    Servers to store data</span></p>
    
    <p class="MsoNormal"><span >Despite these measures, no transmission method over the
    internet or electronic storage is 100% secure. We cannot guarantee absolute
    security of your personal data.</span></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    <p class="MsoNormal"><b><span >8. Changes to This Privacy Policy</span></b></p>
    
    <p class="MsoNormal"><span >We may update this Privacy Policy from time to time. If we
    make material changes, we will notify you by posting the new policy on our
    Site, and we may also send you an email or other notification. Your continued
    use of the Services after any change in this Privacy Policy will constitute
    your acceptance of such change.</span></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    <p class="MsoNormal"><b><span >9. Contact Us</span></b></p>
    
    <p class="MsoNormal"><span >If you have any questions, concerns, or requests regarding
    this Privacy Policy or our data practices, please contact us at </span><a href="mailto:data@palace.com"><span >data@palace.com</span></a></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    <p class="MsoNormal"><span >Effective date: April 5, 2025</span></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    <p class="MsoNormal"><span >&nbsp;</span></p>
    
    
    
    </div> );
}
 
export default Privacy;